import React, {useEffect} from "react"; 
import { useParams } from "@chainsafe/common-components"
import { useLanguageContext } from "../LanguageContext"
import Hero from "./Modules/Hero";
import NavBar from "./Modules/Navbar";
import Featured from "./Modules/Features";
import Footer from "./Modules/Footer";
import FactSection from "./Modules/FactSection";

const HomePage: React.FC = () => {
    const {lang} = useParams();
    
    const { availableLanguages, selectedLanguage, setActiveLanguage } = useLanguageContext()
    useEffect(() => {
    if (!lang) return

    const availableRoute = availableLanguages.find((availableLanguage) => availableLanguage.id === lang)

    if(availableRoute !== undefined && selectedLanguage !== lang ){
      setActiveLanguage(lang, false)
    }
  } 
    , [availableLanguages, lang, selectedLanguage, setActiveLanguage]
  )
  return (
    <div>
      <NavBar/>
      <Hero/>
      <FactSection/>
      <Featured/>
      <Footer/>
    </div>
  )
}

export default HomePage;