import React from "react";
import {ITheme, makeStyles, createStyles} from "@chainsafe/common-theme";
import {Typography} from "@chainsafe/common-components";
import clsx from "clsx";

const useStyles = makeStyles(
    ({constants, palette, breakpoints }: ITheme) => {
    return createStyles({
        container: {
          overflowX: "hidden",
          objectFit: "cover",
          minHeight: "50vh",
          objectPosition: "center",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          [breakpoints.up(2560)]: {
            maxWidth: "1350px",
            minHeight: "30vh",
            position: "relative",
            left: "50%",
            transform: "translate(-50%)",
          },
          [breakpoints.down(767)]:{
            alignItems: "flex-start",
          },
        },
        gridContainer: {
          paddingTop: "80px",
          paddingLeft: "20px",
          paddingRight: "20px",
          width: "100%",
          display: "block",
          maxWidth: "1200px",
        },
        bold: {
          fontWeight: 800,
        },
        reduceLeading: {
          letterSpacing: "-1px",
        },
        white: {
          color: palette.common.white.main,
        },
        orange: {
          color: palette.additional["yellow"][5],
        },
        hoverLink: {
          transition: "all ease-in-out 0.15s",
          "&:hover": {
            color: palette.additional["gray"][2],
          }
        },
        singleColText: {
          textAlign: "left",
        },
        marginTop: {
          marginTop: "1.25rem",
          [breakpoints.down(767)]: {
            marginTop: "1rem",
          },
        },
        noUnderline: {
        textDecoration: "none",
        },
        leftFeaturedItem: {
          [breakpoints.down(1300)]: {
            width: "calc(60% - 50px)",
            marginLeft: "20px",
            marginBottom: "80px",
            marginRight: "20px",
          },  
          [breakpoints.down(1079)]: {
            width: "calc(80% - 40px)",
            marginLeft: "20px",
            marginRight: "20px",
            marginBottom: "50px",
          },
          [breakpoints.down(767)]:{
            marginBottom: "50px",
          },
          [breakpoints.down(599)]: {
            width: "calc(100% - 20px)",
            marginLeft: "10px",
            marginRight: "10px",
          },
          width: "calc(70% - 100px)",
          marginLeft: "20px",
          marginBottom: "80px",
          marginRight: "20px",
          display: "flex",
          flexDirection: "column",
          float: "left",
        },
        rightFeaturedItem: {
          [breakpoints.down(1300)]: {
            width: "calc(60% - 50px)",
            marginLeft: "20px",
            marginBottom: "80px",
            marginRight: "20px",
          },  
          [breakpoints.down(1079)]: {
            width: "calc(80% - 40px))",
            marginLeft: "20px",
            marginRight: "20px",
            marginBottom: "50px",
          },
          [breakpoints.down(767)]:{
            marginBottom: "50px",
          },
          [breakpoints.down(599)]: {
            width: "calc(100% - 20px)",
            marginLeft: "10px",
            marginRight: "10px",
          },
          textAlign: "right",
          width: "calc(70% - 100px)",
          marginLeft: "20px",
          marginBottom: "80px",
          marginRight: "20px",
          display: "flex",
          flexDirection: "column",
          float: "right",
        },
        rightAligned: {
          textAlign: "right",
        },
        floatRight: {
          float: "right"
        },
        gray: {
          color: "#bfbfbf",
        },
        button: {
          background: "transparent",
          border: `1px solid ${palette.primary.main}`,
          borderRadius: "12px",
          fontSize: "18px",
          lineHeight: "26px",
          maxWidth: "80px",
          padding: "2rem 0" ,
          marginTop: constants.generalUnit * 1.5,
          transition: "all ease-in-out 0.2s",
          "&:hover, &:active, &:focus": {
            background: "transparent",
            border: "1px solid white",
          }
      },
    })
})

const FactSection: React.FC = () => {
  const classes = useStyles();
  return (
    <section className={classes.container}>
      <div className={classes.gridContainer}>
          <article className={classes.leftFeaturedItem}>
            <Typography variant="h5" className={clsx(classes.orange, classes.bold)}>
              UPLOAD
            </Typography>
            <Typography variant="h3" className={clsx(classes.white, classes.bold)}>
              Pin your files in-app or on the CLI. 
            </Typography>
            <Typography variant="h4" className={clsx(classes.white, classes.marginTop, classes.singleColText)}>
              Pinning a file ensures that the content you upload to IPFS persists.
            </Typography>
          </article>
          <article className={classes.rightFeaturedItem}>
            <Typography variant="h5" className={clsx(classes.orange, classes.bold)}>
              MIGRATE
            </Typography>
            <Typography variant="h3" className={clsx(classes.white, classes.bold)}>
              Move from S3 by changing a single value. 
            </Typography>
            <Typography variant="h4" className={clsx(classes.white, classes.marginTop, classes.singleColText, classes.rightAligned)}>
              Migrate seamlessly to more distributed cloud storage.
            </Typography>
          </article>
          <article className={classes.leftFeaturedItem}>
            <Typography variant="h5" className={clsx(classes.orange, classes.bold)}>
              STORE
            </Typography>
            <Typography variant="h3" className={clsx(classes.white, classes.bold)}>
              Secure off-chain storage for NFT metadata.
            </Typography>
            <Typography variant="h4" className={clsx(classes.white, classes.marginTop, classes.singleColText)}>
              Storage can provide peace of mind that the associated off-chain data for your NFTs will always be just as available and decentralized as the asset itself!
            </Typography>
          </article>
      </div>
    </section>
  )
}

export default FactSection;
