import React from "react";
import { createStyles, ITheme, makeStyles } from "@chainsafe/common-theme";
import {Button, Typography} from "@chainsafe/common-components";
import clsx from "clsx";
import { Trans } from "@lingui/macro";

const useStyles = makeStyles(
    ({constants, palette, breakpoints, typography}: ITheme) => {
    return createStyles({
        container: {
          background: "url('/test.png')",
          minHeight: "60vh",
          [breakpoints.up(1300)]: {
            height: "calc(100% - 180)",
            paddingTop: "90px",
            paddingBottom: "90px",
          },
          [breakpoints.up(1980)]: {
            height: "calc(100% - 360px)",
            paddingTop: "180px",
            paddingBottom: "180px",
            minHeight: "60vh",
          },
          [breakpoints.up(2560)]: {
            height: "calc(100% - 600px)",
            paddingTop: "300px",
            paddingBottom: "300px",
            minHeight: "60vh",
          },
          [breakpoints.down(1300)]: {
            height: "calc(100% - 120px)",
            paddingTop: "60px",
            paddingBottom: "60px",
          },
          [breakpoints.down(1079)]: {
             height: "calc(100% - 180px)",
            paddingTop: "90px",
            paddingBottom: "90px",
          },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        heroContentContainer: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "2rem",
          [breakpoints.up('md')]:{
            alignItems: "center",
          },
          [breakpoints.down('md')]: {
            alignItems: "flex-start",
          },
        },
        bold: {
          fontWeight: 800,
        },
        reduceLeading: {
          letterSpacing: "-1.25px",
        },
        white: {
          color: palette.common.white.main,
        },
        singleColText: {
          [breakpoints.up('md')]:{
            textAlign: "center",
          },
          [breakpoints.down('md')]: {
            textAlign: "left"
          },
          maxWidth: 600,
        },
        yellow:{ 
          color: palette.additional["yellow"][4],
        },
        button: {
          background: palette.additional["yellow"][5],
          color: palette.common.black.main,
          fontSize: "18px",
          lineHeight: "26px",
          padding: constants.generalUnit,
          transition: "all ease-in-out 0.1s",
          "&:hover, &:active, &:focus": {
            background: palette.additional["yellow"][6]
          }
        }
    })
})

const Hero: React.FC = () => {
    const classes = useStyles();
    return (
      <div className={classes.container}>
        <section>
          <div className={classes.heroContentContainer}>
            <Typography variant="h2" className={clsx(classes.bold, classes.reduceLeading, classes.white)}>
              <Trans>
                Upload to the IPFS/Filecoin network, trust and fuss minimized.
              </Trans>
            </Typography>
            <br/>
            <Button 
            size="large" variant="danger" className={clsx(classes.bold, classes.button)}
            ><a href="https://app.storage.chainsafe.io/" target="__blank" rel="noopener noreferrer"><Trans>Launch app</Trans></a></Button>
          </div>
        </section>
      </div>
    )
}

export default Hero;