import React from "react"
import { createStyles, ITheme, makeStyles } from "@chainsafe/common-theme"
import { Typography, useScrollToTop } from "@chainsafe/common-components"
import clsx from "clsx"
import { Trans } from "@lingui/macro"

const useStyles = makeStyles(
  ({ palette, constants, breakpoints, typography }: ITheme) =>
    createStyles({
      container: {
        maxWidth: breakpoints.width("md"),
        margin: "0 auto",
        color: palette.additional["gray"][9],
        padding: `${constants.generalUnit * 16}px ${constants.generalUnit * 4
          }px ${constants.generalUnit * 20}px`,
      },
      heading: {
        ...typography.h1,
        fontFamily: "'Neue Montreal, Arial', sans-serif",
        letterSpacing: "-4px",
      },
      subHeading: {
        ...typography.h3,
        fontFamily: "'Neue Montreal, Arial', sans-serif",
        letterSpacing: "-2px",
      },
      title: {
        ...typography.h4,
        paddingTop: constants.generalUnit * 3,
        fontFamily: "'Neue Montreal, Arial', sans-serif",
        fontWeight: 600,
        letterSpacing: "-1px",
      },
      tableHeading: {
        ...typography.h5,
        paddingTop: constants.generalUnit * 3,
        fontFamily: "'Neue Montreal, Arial', sans-serif",
        fontWeight: 600,
        letterSpacing: "-.5px",
      },
      text: {
        ...typography.body1,
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "'Neue Montreal, Arial', sans-serif",
        letterSpacing: "-.25px",
      },
      caption: {
        ...typography.body1,
        fontFamily: "'Neue Montreal, Arial', sans-serif",
      },
      padLarge: {
        padding: `${constants.generalUnit * 3}px 0 ${constants.generalUnit * 1
          }px 0`,
      },
      padSmall: {
        padding: `${constants.generalUnit * 1}px 0`,
      },
      orderedList: {
        ...typography.body1,
        padding: "revert",
        listStyle: "decimal",
        fontFamily: "'Neue Montreal, Arial', sans-serif",
        fontSize: "16px",
        lineHeight: "24px",
        margin: `${constants.generalUnit * 2}px 0`,
      },
      unorderedList: {
        ...typography.body1,
        padding: "revert",
        listStyle: "disc",
        fontFamily: "'Neue Montreal, Arial', sans-serif",
        fontSize: "16px",
        lineHeight: "24px",
        margin: `${constants.generalUnit * 2}px 0`,
      },
      letteredList: {
        ...typography.body1,
        padding: "revert",
        listStyle: "lower-alpha",
        fontFamily: "'Neue Montreal, Arial', sans-serif",
        fontSize: "16px",
        lineHeight: "24px",
        margin: `${constants.generalUnit * 2}px 0`,
        "& > li": {
          marginTop: constants.generalUnit,
        }
      },
      romanList: {
        ...typography.body1,
        padding: "revert",
        listStyle: "lower-roman",
        fontFamily: "'Neue Montreal, Arial', sans-serif",
        fontSize: "16px",
        lineHeight: "24px",
        margin: `${constants.generalUnit * 2}px 0`,
        "& > li": {
          marginTop: constants.generalUnit,
        }
      },
      emailLink: {
        ...typography.body1,
        color: palette.additional["gray"][9],
        fontSize: "16px",
        lineHeight: "24px",
        transition: "all 0.3s",
        "&:hover": {
          color: palette.primary.main,
        }
      },
      table: {
        border: "1px solid #0f0f0f",
        padding: `${constants.generalUnit * 2}px`,
        [breakpoints.down('md')]: {
          border: ".25px solid black",
        }
      },
      tr: {
        padding: `${constants.generalUnit}px`,
        border: "1px solid #0f0f0f",
        [breakpoints.down('md')]: {
          display: "flex",
          flexDirection: "column",
          border: ".25px solid black",
        }
      }
    }),
)

const PrivacyPolicy: React.FC = () => {
  const classes = useStyles()
  useScrollToTop(true);

  return (
    <div style={{background: "#fafafa"}}>
      <div className={classes.container}>
        <Typography
          component="h1"
          className={clsx(classes.heading, classes.padLarge)}
        >
          <Trans>Privacy Policy</Trans>
        </Typography>

        <Typography
          component="p"
          className={clsx(classes.caption, classes.padSmall)}
        >
          <Trans>Last Modified: July 15 2021</Trans>
        </Typography>
        <Typography
          component="p"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            Welcome! Here at ChainSafe Systems Inc. (<strong>“ChainSafe”</strong>, <strong>“we”</strong>, <strong>“us”</strong> and similar meaning terms), we respect your privacy and are committed to protecting it. This privacy policy (<strong>“Privacy Policy”</strong>) describes how we collect, use, share and protect the personal information of our users (<strong>“you”</strong>) on ChainSafe Storage (the <strong>“Service”</strong>, located at https://storage.chainsafe.io).
          </Trans>
        </Typography>
        <Typography
          component="p"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            We take steps to ensure that the personal information we collect about you is adequate, relevant, minimal, and used for limited purposes. By accessing the Service, you confirm that you have read and understand our Terms of Service (the “Terms”), found here, and this Privacy Policy. Any capitalized term not defined in this Privacy Policy is incorporated by reference from our Terms.
          </Trans>
        </Typography>

        <Typography
          component="h2"
          variant="h2"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>The gist of it </Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            As a privacy-first file storage service, we take your privacy rights very seriously. Our services are designed to limit the personal information we collect from you. We will never sell your information to third-parties and will never bother you with advertisements. In keeping privacy top of mind, our goal is to make sure that any information we require from you is necessary for the limited purposes you consent to.
          </Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            This Privacy Policy describes the personal information we may collect, the reasons we collect it, how we use it, and how we keep it secure. ChainSafe will not collect, use or disclose your personal information in any way other than in accordance with this policy, unless obligated by applicable law.

          </Trans>
        </Typography>
        <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>Personal information we (directly) collect</Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            We may need to collect some personal information to provide the Service to you in a secure and safe manner. Our collection and use of your personal information will be limited to that which is necessary to provide you with a tailored experience.
          </Trans>
        </Typography>
        <ol className={clsx(classes.orderedList, classes.padLarge)}>
          <li>
            <Trans>
              <strong>Information you may provide to us:</strong> through the course of using the Service, you may be prompted to provide us with your: (i) name; (ii) email address; (iii) password; (iv) authentication information; (v) Web3 wallet; and (vi) payment information.
            </Trans>
          </li>
        </ol>
      
        <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>Personal information we (indirectly) collect</Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            ChainSafe Storage uses automatic data collection technologies to collect personal information related to your equipment, browsing actions and usage patterns on the Service. We may utilize and integrate third-party service providers into the Service that use cookies to collect personal information. Any information derived through our use of cookies and other data collection technologies is used to provide us with analytics and other improvements to the Service. Information indirectly collected may include:
          </Trans>
        </Typography>
        <ol className={clsx(classes.orderedList, classes.padLarge)}>
          <li>
            <Trans>
              <strong>Technical information:</strong> this includes your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform, information about your internet connection (IP address), the equipment you use to access the Service, and usage details. This might also include IDs of errors, communications with the support team or bug reports to improve the Service.

            </Trans>
          </li>
          <br/>
          <li>
            <Trans>
              <strong>Statistical and Aggregated information:</strong> this includes information that we receive across users that does not directly identify a person. Any aggregated information will be anonymized and de-identified if analyzed and used.
            </Trans>
          </li>
          <br />
          <li>
            <Trans>
              <strong>Details of your Service Usage:</strong> this includes traffic data, logs, other communication data and the resources that you access and use. This may also include the URLs, pages you viewed or searched for, page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), methods used to browse away from the page, or any phone number used to call us.
            </Trans>
          </li>
        </ol>
    
        <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>When/how we collect information:</Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            We collect personal information from you when you sign up for a User Account and when you update your User Account’s information. We may collect your personal information from third-party service providers that integrate with the Service.
          </Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            <strong>OAuth Authentication:</strong> if you register for a User Account using our OAuth service, your name, email and username from the OAuth service may be collected and stored to identify your User Account.
          </Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            <strong>Ethereum Authentication: </strong> if you register using an Ethereum address, we will store the Ethereum address to associate it with your User Account. We will store your email address only if you opt-in to providing it. An email address is not required for users accessing the Service with an Ethereum address.
          </Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            We also collect your personal information when you:
          </Trans>
        </Typography>
        <ol className={clsx(classes.padLarge, classes.orderedList)}>
          <li>
            <Trans>
              Use the Service to download any file, software, media or other content from it.
            </Trans>
          </li>
          <li>
            <Trans>
              Communicate with us through email, social media, phone or any other means.
            </Trans>
          </li>
          <li>
            <Trans>
              Provide us with your name, email address or other identifying information.
            </Trans>
          </li>
        </ol>

      
        <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>How we use your information:</Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            We use the information that we collect about you or that you provide to us through providing the Service. We may use your information:
          </Trans>
        </Typography>
        <ol className={clsx(classes.padLarge, classes.orderedList)}>
          <li>
            <Trans>
              To operate the Service and make its contents available to you. We do not monitor or track User Content except as may be required by applicable law;
            </Trans>
          </li>
          <br />
          <li>
            <Trans>
              To provide you with information, products or services that you request from us. We use your information to (i) provide you with a personalized service; (ii) facilitate payments on the Service; and (iii) communicate with you via email, text message, push notification, or otherwise;
            </Trans>
          </li>
          <br />
          <li>
            <Trans>
              To contact you via email, SMS, push notifications, or otherwise to provide administrative notices relevant to your use of the Service;
            </Trans>
          </li>
          <br />
          <li>
            <Trans>
              To provide you with customer support and assist with the provision of the Service, or to assist in resolving complaints or disputes relating to the Service;
            </Trans>
          </li>
          <br />
          <li>
            <Trans>
              To notify you about changes to our Service or any products or features we offer or provide through it;
            </Trans>
          </li>
          <br />
          <li>
            <Trans>
              To enforce our Terms and other agreements, including for billing or notifying you about changes to our Service or any products or services that we offer;
            </Trans>
          </li>
          <br />
          <li>
            <Trans>
              To fulfill the purposes for which you provided the information or that were described when it was collected or for any other purpose for which you provide it;
            </Trans>
          </li>
          <br />
          <li>
            <Trans>
              To carry out our obligations and comply with applicable law; and
            </Trans>
          </li>
          <br />
          <li>
            <Trans>
              For any other purpose with your consent and as otherwise outlined in our Terms.
            </Trans>
          </li>
        </ol>

        <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>Disclosure of your information </Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            We may disclose the information that we collect about you or that you provide to the Service in accordance with this Privacy Policy. We may share your personal information with third-parties for the following reasons:
          </Trans>
        </Typography>
        <ol className={clsx(classes.romanList, classes.padLarge)}>
          <li>
            <Trans>
              <strong>Affiliated Companies:</strong> We may share your information with our subsidiaries, parent company and other related entities to effectively provide you with the Service.
            </Trans>
          </li>
          <li>
            <Trans>
              <strong>Third-Party Service Providers:</strong> We may share your information with trusted service providers, contractors, the organizations they act on behalf of, and other third-parties (such as decentralized protocols) that we use to support our business. These third-party service providers may require us to provide them with your information to perform or facilitate these functions. If we provide them with your information, we will contractually obligate them to keep your information confidential, use it only for the purposes for which we disclose it to them, and to process the personal information with the same standards set out in this policy.
            </Trans>
          </li>
          <li>
            <Trans>
              <strong>Payment Processors:</strong> Users may be able to submit payment information into the Service to pay for certain features. ChainSafe utilizes third-party payment processors to facilitate payments. We do not receive nor store any payment information on our servers. All payment transactions are encrypted when communicated through our third-party payment processor.
            </Trans>
          </li>
          <li>
            <Trans>
              <strong>Storage Providers:</strong> We may process, store, and transfer your personal information in and to a foreign country, with different privacy laws that may or may not be as comprehensive as privacy laws in Canada. Whenever we engage a service provider, we require that its privacy and security standards adhere to this policy and applicable Canadian privacy legislation.
            </Trans>
          </li>
          <li>
            <Trans>
              <strong>Commercial Partners:</strong> We may share your information or anonymized aggregated data derived from your information and the information of others with some of our commercial partners.
            </Trans>
          </li>
          <li>
            <Trans>
              <strong>Legal Requirements</strong> We may disclose personal information in order to comply with any court order, law, or legal process, including to respond to any government or regulatory request in accordance with applicable law.
            </Trans>
          </li>
          <li>
            <Trans>
              <strong>Enforcement</strong> We may disclose personal information in order to establish or exercise our legal rights, or to defend against legal claims, or when we believe it is necessary to share information in order to investigate, prevent, or take action regarding illegal activities, data breaches, suspected fraud, situations involving potential threats to the physical safety of any person, violations of our Terms of Service, or as otherwise required by law.
            </Trans>
          </li>
          <ol className={clsx(classes.letteredList, classes.padLarge)}>
            <li>
              <Trans>
                <strong>Applicable Law:</strong> there may be laws under the jurisdictions that we operate in that compel us to disclose personal information or account data. If required, we may provide personal information or User Account files that we receive from you or a third-party in an unencrypted format.
              </Trans>
            </li>
            <li>
              <Trans>
                <strong>Violent, Pornographic or other Illegal Content:</strong> it is a violation of our Terms of Service to upload illegal content or content that may affect the safety or well-being of others. If we are made aware that any User Content that you transmit through the Service contains material relating to child pornography, acts of terrorism, violent actions or other illegal material, we may report it to the relevant authorities and remove the User Content from the Service.
              </Trans>
            </li>
          </ol>
          <li>
            <Trans>
              <strong>Business Transactions:</strong> ChainSafe reserves the right to share your information in connection with any proposed or realized merger, divestiture, consolidation, restructuring, purchase, sale or any other type of business combination that requires its disclosure.
            </Trans>
          </li>
        </ol>
        <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>Data security</Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            ChainSafe Storage uses physical, electronic, technical and administrative safeguards designed to secure your personal information from accidental loss and unauthorized access, use, modification, and disclosure. Although we implement measures to protect your personal information, we cannot guarantee the security of your personal information transmitted to the Service over the internet. Any transmission of personal information through the Service is done at your own risk. We are not responsible for your circumvention of any privacy settings or security measures contained in the Service.
          </Trans>
        </Typography>
  
        <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>Data retention</Trans>
        </Typography>
        <ol className={clsx(classes.romanList, classes.padLarge)}>
          <li>
            <Trans>
              <strong>Retention Policy.</strong> To improve the Service, we may aggregate information from users after removing information that can allow them to be identified. You acknowledge and agree that we may use anonymized personal information for legitimate business purposes that cannot be associated with you or the User. We reserve the right to use such anonymized information without further notice.
            </Trans>
            <br/>
            <Trans>
              We may periodically de-identify or delete personal information from inactive user accounts and unnecessary information that is flagged by our system. With the exception of aggregated or anonymized data, we will delete unnecessary or unused personal information after two (2) years of inactivity on the Service.
            </Trans>
          </li>
          <li>
            <Trans>
              <strong>Termination of Account.</strong> If your account on the Service is terminated, we may retain your personal information for a certain period of time (e.g., archive your personal information), which will be disclosed to you and performed in accordance with our practices under this Privacy Policy.
            </Trans>
          </li>
        </ol>
        <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>User Rights</Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            If you would like to exercise any of the following rights, please contact us at <a href="mailto:support@chainsafe.io" className={classes.emailLink}>support@chainsafe.io</a>
          </Trans>
        </Typography>
        <ol className={clsx(classes.letteredList, classes.padLarge)}>
          <li>
            <Trans>
              <strong>Accessing, Correcting or Updating Your Information:</strong> You may request for us at any time to correct your information or to provide you with a copy of your personal information that we maintain in electronic format.

            </Trans>
          </li>
          <li>
            <Trans>
              <strong>Deleting Your Information:</strong> You may request for us at any time to have your personal information that we maintain deleted. We will comply with your request unless there are legitimate business or legal grounds for the continued retention of your personal information.
            </Trans>
          </li>
          <li>
            <Trans>
              <strong>Object to our Processing:</strong> You may request for us to restrict or cease the processing of your personal information if you believe it has been processed (i) in a manner contrary to applicable law; (ii) in a manner outside of the consent you provided; or (iii) after your consent has been withdrawn. We will comply with your request unless there are legitimate business or legal grounds for the continued processing of your personal information.


            </Trans>
          </li>
          <li>
            <Trans>
              <strong>Withdrawing Consent:</strong> You may withdraw your consent for our collection, use and disclosure of your personal information at any time. We will comply with your request unless it conflicts with our legal obligations or an established standard of practice. Please be aware that we may be unable to provide you with the Service if you withdraw your consent.
            </Trans>
          </li>
          <li>
            <Trans>
              <strong>Transfers of Data:</strong> We and our affiliates and third-party service providers primarily store data about you, on decentralized servers located globally. If you reside or are located outside of Canada, we may send and store your data to Canada in order to provide and operate the Service. ChainSafe undertakes to ensure an adequate level of protection of any personal information transferred outside of Canada.
            </Trans>
          </li>
        </ol>
        <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>Opting-Out of Communications</Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            We may send periodic marketing, promotional or informational emails to you in accordance with your communication preferences. These may include emails for future events and products. You may opt-out of such communications at any time by following the opt-out instructions contained in the email or by contacting us at the email below.
          </Trans>
        </Typography>
        <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>Children’s Privacy</Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            The Service is not intended for children under thirteen (13) years of age. We do not knowingly collect personal information from children under thirteen (13) years of age. If you are under eighteen (18) years of age, we must receive your parent or legal guardian’s consent before collecting, using, or disclosing your information on the Service. If we learn we have collected or received personal information from a child without verification of parental consent, we will delete that information. If you (i) believe we might have any information from or about a child; (ii) would like to review any personal information we may have collected about your child; or (iii) would like to request the deletion of your child’s personal information, please contact our Privacy Officer at the email address below.
          </Trans>
        </Typography>
        <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>Changes to our Privacy Policy</Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            We may make changes to our Privacy Policy from time to time. It is our policy to post any changes we make on this page. If we make any material changes to how we treat your personal information, we will notify you by emailing the email address specified in your account or  posting a notice about the changes on a conspicuous area of the Service.
          </Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            We include the date the privacy policy was last revised at the top of the page. You are responsible for ensuring we have: (i) an up-to-date, active, and deliverable email address; and (ii) for periodically visiting our Service and this Privacy Policy to check for any changes.
          </Trans>
        </Typography>
        <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>Contact</Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            If you have any questions, comments, or requests regarding this Privacy Policy or our privacy practices, please contact us at:
          </Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            <strong>Privacy Officer</strong>
          </Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            <a href="mailto:support@chainsafe.io" className={classes.emailLink}>support@chainsafe.io</a>
          </Trans>
        </Typography>
        <Typography
          component="h1"
          className={clsx(classes.subHeading, classes.padLarge)}
        >
          <Trans>Additional Terms for Residents of the European Economic Area (EEA) and the United Kingdom</Trans>
        </Typography>
        <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>Controller</Trans>
        </Typography>
         <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            ChainSafe Systems Inc. is the controller and responsible for your personal data (collectively referred to as “<strong>ChainSafe</strong>”, “<strong>we</strong>”, “<strong>us</strong>” or “<strong>our</strong>” in this privacy policy).
            We have appointed a data protection officer (“<strong>DPO</strong>”) responsible for overseeing questions in relation to this privacy policy. If you have any questions about this privacy policy, including any requests to exercise your legal rights, please contact the DPO using the details set out below.
          </Trans>
        </Typography>
        <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>Contact Details</Trans>
        </Typography>
         <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            If you have any questions about this privacy policy or our privacy practices, please contact our DPO:
          </Trans>
        </Typography>
         <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
            Priom Chowdhury
            251 Spadina Avenue, Unit 204, M5T 2E2
            Toronto, Ontario Canada
            <a href="mailto:support@chainsafe.io" className={classes.emailLink}>support@chainsafe.io</a>
        </Typography>
         <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            You have the right to make a complaint at any time, but we would appreciate the chance to deal with your concerns before any complaint is made, so please contact us in the first instance. 
          </Trans>
        </Typography>
        <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>Changes to the Privacy Policy</Trans>
        </Typography>
         <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            We keep our privacy policy under regular review. This version was last updated on the date listed above. It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.
          </Trans>
        </Typography>
        <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>Third-Party Links</Trans>
        </Typography>
         <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            The Service may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave the Service, we encourage you to read the privacy policy of every website you visit.
          </Trans>
        </Typography>
        <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>The Data We Collect About You</Trans>
        </Typography>
         <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            In this section, personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data). 
          </Trans>
        </Typography>
         <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            We may collect, use, store and transfer different kinds of personal data about you, which we have grouped together as follows: 
          </Trans>
        </Typography>
        <ul className={clsx(classes.unorderedList, classes.padLarge)}>
          <li>
            <Trans>
              <strong>Identity Data</strong> includes first name, last name.
            </Trans>
          </li>
          <li>
            <Trans>
              <strong>Contact Data</strong> includes billing address, email address and telephone numbers.
            </Trans>
          </li>
          <li>
            <Trans>
              <strong>Financial Data</strong> includes bank account and payment card details.
            </Trans>
          </li>
          <li>
            <Trans>
              <strong>Transaction Data</strong> includes details about payments to and from you and other details of products and services you have purchased from us. 
            </Trans>
          </li>
          <li>
            <Trans>
              <strong>Technical Data</strong> includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access the Service.
            </Trans>
          </li>
          <li>
            <Trans>
              <strong>Profile Data</strong> includes your username and password, purchases or orders made by you, your preferences, feedback and survey responses.
            </Trans>
          </li>
          <li>
            <Trans>
              <strong>Usage Data</strong> includes information about how you use the Service and products.
            </Trans>
          </li>
        </ul>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            We may also collect, use and share aggregated data such as statistical or demographic data for any purpose. Aggregated data could be derived from your personal data but is not considered personal data in law as this data will not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific Service feature. However, if we combine or connect aggregated data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data, which will be used in accordance with this privacy policy.
          </Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            We do not collect any special categories of personal data about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health, and genetic and biometric data). Nor do we collect any information about criminal convictions and offences.
          </Trans>
        </Typography>
        <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>Failure to Provide Personal Data</Trans>
        </Typography>
         <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            Where we need to collect personal data by law, or under the terms of a contract we have with you, and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with goods or services). In this case, we may have to cancel a product or service you have with us but we will notify you if this is the case at the time. 
          </Trans>
        </Typography>
        <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>How is Your Personal Data Collected?</Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            We use different methods to collect data from and about you including through:
          </Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            <strong>Direct interactions. </strong> You may give us your Identity, Contact and Financial Data by filling in forms or by corresponding with us by post, email or otherwise. This includes personal data you provide when you:

          </Trans>
        </Typography>
        <ul className={clsx(classes.unorderedList, classes.padLarge)}>
          <li>
            <Trans>
              Apply for products through the Service;
            </Trans>
          </li>
          <li>
            <Trans>
              Create an account on the Service; or
            </Trans>
          </li>
          <li>
            <Trans>
              Give us feedback or contact us.
            </Trans>
          </li>
        </ul>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            <strong>Automated technologies or interactions. </strong> As you interact with the Service, we will automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies, server logs and other similar technologies. Please see our cookie policy for further details.
          </Trans>
        </Typography>
         <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>How We Use Your Personal Data</Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:
          </Trans>
        </Typography>
        <ul className={clsx(classes.unorderedList, classes.padLarge)}>
          <li>
            <Trans>
              Where we need to perform the contract we are about to enter into or have entered into with you.
            </Trans>
          </li>
          <li>
            <Trans>
              Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.
            </Trans>
          </li>
          <li>
            <Trans>
              Where we need to comply with a legal obligation.
            </Trans>
          </li>
        </ul>
        <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>Purposes for Which We Will Use Your Data</Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            In a table format, we have set out a description of all the ways we plan to use your data and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are where appropriate.
          </Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your data. Please contact us if you need details about the specific legal ground we are relying on to process your personal data where more than one ground has been set out in the following table:
          </Trans>
        </Typography>
        <br/>
        <table className={clsx(classes.table, classes.padSmall)}>
          <thead>
              <tr className={classes.tr}>
                <th className={classes.tr}>
                  <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.tableHeading, classes.padLarge)}>
                    <Trans>Purposes/Activity</Trans>
                  </Typography>
                </th>
                <th className={classes.tr}>
                  <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.tableHeading, classes.padLarge)}>
                    <Trans>Examples of Personal Data that may be collected</Trans>
                  </Typography>
                </th>
                <th className={classes.tr}>
                  <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.tableHeading, classes.padLarge)}>
                    <Trans>Lawful basis for processing including basis of legitimate interest</Trans>
                  </Typography>
                </th>
              </tr>
          </thead>
          <tbody>
            <tr>
              <td  className={classes.tr}>
                 <Typography
                  component="p"
                  variant="body1"
                  className={clsx(classes.text, classes.padSmall)}
                >
                  <Trans>
                    To register you as a new customer:
                  </Trans>
                </Typography>
                <ol className={clsx(classes.letteredList, classes.padSmall)}>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                        Create an account
                      </Trans>
                    </Typography>
                  </li>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                        Provide the Service
                      </Trans>
                    </Typography>
                  </li>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                        Communicate with you
                      </Trans>
                    </Typography>
                  </li>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                         Respond to your requests and provide support
                      </Trans>
                    </Typography>
                  </li>
                </ol>
              </td>
              <td  className={classes.tr}>
                <ol className={clsx(classes.letteredList, classes.padSmall)}>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                        <strong>Identity Data – </strong> first name, last name
                      </Trans>
                    </Typography>
                  </li>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                        <strong>Contact Data – </strong> email, phone number, address
                      </Trans>
                    </Typography>
                  </li>
                </ol>
              </td>
              <td  className={classes.tr}>
                <Typography
                component="p"
                variant="body1"
                className={clsx(classes.text, classes.padSmall)}
                >
                  <Trans>
                    Performing our contractual responsibilities with you
                  </Trans>
                </Typography>
              </td>
            </tr>
            <tr>
              <td  className={classes.tr}>
                 <Typography
                  component="p"
                  variant="body1"
                  className={clsx(classes.text, classes.padSmall)}
                >
                  <Trans>
                    To process and deliver your order, including:
                  </Trans>
                </Typography>
                <ol className={clsx(classes.letteredList, classes.padSmall)}>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                        Manage payments, fees and charges
                      </Trans>
                    </Typography>
                  </li>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                        Collect and recover money owed to us
                      </Trans>
                    </Typography>
                  </li>
                </ol>
              </td>
              <td  className={classes.tr}>
                <ol className={clsx(classes.letteredList, classes.padSmall)}>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                        <strong>Contact Data – </strong> billing name and address, email address
                      </Trans>
                    </Typography>
                  </li>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                        <strong>Financial Data – </strong> bank account, crypto wallet or payment card details

                      </Trans>
                    </Typography>
                  </li>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                        <strong>Transaction Data – </strong> includes details about payments to and from you and other details of products and services you have purchased from us.
                      </Trans>
                    </Typography>
                  </li>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                        <strong>Profile Data – </strong> purchases and orders previously made
                      </Trans>
                    </Typography>
                  </li>                  
                </ol>
              </td>
              <td  className={classes.tr}>
                <Typography
                component="p"
                variant="body1"
                className={clsx(classes.text, classes.padSmall)}
                >
                  <Trans>
                    Performing our contractual responsibilities with you and recovering debts owed to us (if applicable)
                  </Trans>
                </Typography>
              </td>
            </tr>
            <tr>
              <td  className={classes.tr}>
                 <Typography
                  component="p"
                  variant="body1"
                  className={clsx(classes.text, classes.padSmall)}
                >
                  <Trans>
                    To manage our relationship with you, which includes:
                  </Trans>
                </Typography>
                <ol className={clsx(classes.letteredList, classes.padSmall)}>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                         Notifying you about changes to our terms or privacy policy
                      </Trans>
                    </Typography>
                  </li>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                        Asking you to leave a review or take a survey
                      </Trans>
                    </Typography>
                  </li>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                        Protect us from legal claims and enforce our legal rights
                      </Trans>
                    </Typography>
                  </li>
                </ol>
              </td>
              <td  className={classes.tr}>
                <ol className={clsx(classes.letteredList, classes.padSmall)}>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                        <strong>Identity Data – </strong> first name, last name, user name
                      </Trans>
                    </Typography>
                  </li>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                        <strong>Contact Data – </strong> email address
                      </Trans>
                    </Typography>
                  </li>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                        <strong>Profile Data – </strong> username, preferences, feedback and survey responses
                      </Trans>
                    </Typography>
                  </li>  
                </ol>
              </td>
              <td  className={classes.tr}>
                 <ol className={clsx(classes.letteredList, classes.padSmall)}>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                       Performing our contractual responsibilities with you 
                      </Trans>
                    </Typography>
                  </li>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                        Necessary compliance with our legal obligations
                      </Trans>
                    </Typography>
                  </li>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                        Keeping our records up-to-date and studying how customers use the Services
                      </Trans>
                    </Typography>
                  </li>  
                </ol>
              </td>
            </tr>
            <tr>
              <td  className={classes.tr}>
                 <Typography
                  component="p"
                  variant="body1"
                  className={clsx(classes.text, classes.padSmall)}
                >
                  <Trans>
                    To administer and protect our business and the Service (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)
                  </Trans>
                </Typography>
              </td>
              <td  className={classes.tr}>
                <ol className={clsx(classes.letteredList, classes.padSmall)}>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                        <strong>Identity Data – </strong> first name, last name
                      </Trans>
                    </Typography>
                  </li>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                        <strong>Contact Data – </strong> email address, telephone number
                      </Trans>
                    </Typography>
                  </li>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                        <strong>Profile Data – </strong> username, purchases
                      </Trans>
                    </Typography>
                  </li>  
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                        <strong>Usage Data – </strong> information on how you use the Service
                      </Trans>
                    </Typography>
                  </li>  
                </ol>
              </td>
              <td  className={classes.tr}>
                 <ol className={clsx(classes.letteredList, classes.padSmall)}>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                        Necessary for running our business, the provision of administration and IT services, network security, to prevent fraud 
                      </Trans>
                    </Typography>
                  </li>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                        Necessary to comply with our legal obligations
                      </Trans>
                    </Typography>
                  </li>
                </ol>
              </td>
            </tr>
            <tr>
              <td  className={classes.tr}>
                 <Typography
                  component="p"
                  variant="body1"
                  className={clsx(classes.text, classes.padSmall)}
                >
                  <Trans>
                    To use data analytics to improve our Service, customer relationships and experiences
                  </Trans>
                </Typography>
              </td>
              <td  className={classes.tr}>
                <ol className={clsx(classes.letteredList, classes.padSmall)}>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                        <strong>Technical Data – </strong> IP address, browser type and version, operating system, other technology on the devices you use to access the Service
                      </Trans>
                    </Typography>
                  </li>
                </ol>
              </td>
              <td  className={classes.tr}>
                 <ol className={clsx(classes.letteredList, classes.padSmall)}>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                        Necessary to define types of customers for the Service, to keep our Service updated and relevant, to develop our business and to inform our product strategy
                      </Trans>
                    </Typography>
                  </li>
                </ol>
              </td>
            </tr>
            <tr>
              <td  className={classes.tr}>
                 <Typography
                  component="p"
                  variant="body1"
                  className={clsx(classes.text, classes.padSmall)}
                >
                  <Trans>
                    To make suggestions and recommendations to you about goods or services that may be of interest to you
                  </Trans>
                </Typography>
              </td>
              <td  className={classes.tr}>
                <ol className={clsx(classes.letteredList, classes.padSmall)}>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                        <strong>Identity Data – </strong> first name, last name
                      </Trans>
                    </Typography>
                  </li>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                        <strong>Contact Data – </strong> email address and telephone number
                      </Trans>
                    </Typography>
                  </li>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                        <strong>Profile Data – </strong> username, purchases, preferences, feedback, survey responses
                      </Trans>
                    </Typography>
                  </li>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                        <strong>Usage Data – </strong> information about how you use the Service

                      </Trans>
                    </Typography>
                  </li>
                </ol>
              </td>
              <td  className={classes.tr}>
                 <ol className={clsx(classes.letteredList, classes.padSmall)}>
                  <li>
                    <Typography
                    component="p"
                    variant="body1"
                    className={clsx(classes.text, classes.padSmall)}
                    >
                      <Trans>
                        Necessary for our legitimate interests (to develop our products/services and grow our business)
                      </Trans>
                    </Typography>
                  </li>
                </ol>
              </td>
            </tr>
          </tbody>
        </table>
        <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>Data Transfers</Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            Your personal data provided or collected to us may be shared within the group of companies controlled by ChainSafe Systems Inc. This may involve transferring your data outside of the European Economic Area (“<strong>EEA</strong>”). Personal data and files uploaded to the Service directly are encrypted cryptographically before being transferred and split up across the relied upon decentralized network in a manner that would provide uninterpretable data in the event of a potential hack. However, this uninterpretable data may be transferred to countries that do not have the same data protection regulations as the country you provide your personal data in.
          </Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            Our external third parties and the decentralized network the Service relies upon are based outside of the EEA, so their processing of your personal data will involve a transfer of data outside the EEA. When transferring your personal data outside of the EEA, we aim for a similar degree of protection to be afforded to your data by implementing safeguards and security measures. This includes using secure encryption of your personal data as well as certain service providers that provide the same level of protection to personal data that would be applied to in the EEA. Where applicable, we may transfer EEA residents' personal data to partners under a contractual obligation to provide an adequate level of protection to the personal data in accordance with EEA data protection regulations.
          </Trans>
        </Typography>
        <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>Data Retention</Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.
          </Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorized use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements.
          </Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
           In some circumstances, we will anonymize your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.
          </Trans>
        </Typography>
        <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>Your Rights Regarding Personal Data</Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            You have the following rights:
          </Trans>
        </Typography>
        <ol className={clsx(classes.letteredList, classes.padLarge)}>
          <li>
            <Typography
            component="p"
            variant="body1"
            className={clsx(classes.text, classes.padSmall)}
            >
              <Trans>
                <strong>Right of Access. </strong>You have the right to request and obtain access to your personal data (known as a “data subject access request”). This enables you to receive a copy of the personal data we hold about you and check that we are lawfully processing it.
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography
            component="p"
            variant="body1"
            className={clsx(classes.text, classes.padSmall)}
            >
              <Trans>
                <strong>Right of Rectification. </strong>You have the right to request the correction of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, including through providing us with a supplementary statement, though we may need to verify the accuracy of the new data you provide to us.

              </Trans>
            </Typography>
          </li>
          <li>
            <Typography
            component="p"
            variant="body1"
            className={clsx(classes.text, classes.padSmall)}
            >
              <Trans>
                <strong>Right of Erasure. </strong>You have the right to request the erasure of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us to continue to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons, which will be notified to you, if applicable, at the time of your request.
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography
            component="p"
            variant="body1"
            className={clsx(classes.text, classes.padSmall)}
            >
              <Trans>
                <strong>Right to Object to Processing. </strong>You have the right to object to the processing of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms. 
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography
            component="p"
            variant="body1"
            className={clsx(classes.text, classes.padSmall)}
            >
              <Trans>
                <strong>Right to Restriction of Processing. </strong>You have the right to request the restriction of processing of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios:
              </Trans>
            </Typography>
            <ol className={clsx(classes.romanList, classes.padSmall)}>
              <li>
                <Typography
                component="p"
                variant="body1"
                className={clsx(classes.text, classes.padSmall)}
                >
                  <Trans>
                     If you want us to establish the data’s accuracy.
                  </Trans>
                </Typography>                
              </li>
              <li>
                <Typography
                component="p"
                variant="body1"
                className={clsx(classes.text, classes.padSmall)}
                >
                  <Trans>
                     Where our use of the data is unlawful,  but you do not want us to erase it.
                  </Trans>
                </Typography>                
              </li>
              <li>
                <Typography
                component="p"
                variant="body1"
                className={clsx(classes.text, classes.padSmall)}
                >
                  <Trans>
                     Where you need us to hold the personal data even if we no longer require it as you need it to establish, exercise or defend legal claims.
                  </Trans>
                </Typography>                
              </li>
              <li>
                <Typography
                component="p"
                variant="body1"
                className={clsx(classes.text, classes.padSmall)}
                >
                  <Trans>
                     Where you have objected to our use of your personal data but we need to verify whether we have overriding legitimate grounds to use it.
                  </Trans>
                </Typography>                
              </li>
            </ol>
          </li>
          <li>
            <Typography
            component="p"
            variant="body1"
            className={clsx(classes.text, classes.padSmall)}
            >
              <Trans>
                <strong>Right to Portability. </strong>You have the right to request the transfer of your personal data to you or a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography
            component="p"
            variant="body1"
            className={clsx(classes.text, classes.padSmall)}
            >
              <Trans>
                <strong>Right to Withdraw Consent. </strong>You have the right to withdraw consent at any time where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.
              </Trans>
            </Typography>
          </li>
        </ol>
        <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>Cost of Accessing Your Personal Data</Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is unfounded, repetitive or excessive. Alternatively, we could refuse to comply with your request in these circumstances.
          </Trans>
        </Typography>
        <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>What's Needed From You</Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.
          </Trans>
        </Typography>
        <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>Time Limit to Respond</Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if your request is particularly complex or you have made several requests. In this case, we will notify you and keep you updated.
          </Trans>
        </Typography>
          <Typography
          component="h1"
          className={clsx(classes.subHeading, classes.padLarge)}
        >
          <Trans>DISCLOSURES FOR CALIFORNIA RESIDENTS</Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            These disclosures are for residents of California who access and use ChainSafe Storage as private individuals. You may authorize an agent to submit requests under this policy on your behalf. However, we will require written proof of the agent’s authority to do so and verify your identity.
          </Trans>
        </Typography>
        <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>Categories of personal information we collect and use</Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            If you are a resident of California, the following are the categories of information we collect from you:
          </Trans>
        </Typography>
        <ol className={clsx(classes.letteredList, classes.padSmall)}>
          <li>
            <Typography
              component="p"
              variant="body1"
              className={clsx(classes.text)}
            >
              <Trans>
                <strong>Identifiers. </strong>Name, account name, address, IP address, email address, and identifier assigned to your User Account.
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography
              component="p"
              variant="body1"
              className={clsx(classes.text)}
            >
              <Trans>
                <strong>Customer Records. </strong>Name, email address, credit card, Web3 wallet and Ethereum wallet address.
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography
              component="p"
              variant="body1"
              className={clsx(classes.text)}
            >
              <Trans>
                <strong>Analytics. </strong>Engagement with ChainSafe Storage.
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography
              component="p"
              variant="body1"
              className={clsx(classes.text)}
            >
              <Trans>
                <strong>Internet Activity. </strong>Browsing history, browser type, search history, browser language and information regarding your interaction with our Service collected automatically.
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography
              component="p"
              variant="body1"
              className={clsx(classes.text)}
            >
              <Trans>
                <strong>Geolocation Data. </strong>Information that location-enabled services collect through Wi-Fi and GPS.
              </Trans>
            </Typography>
          </li>
        </ol>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text)}
        >
          <Trans>
            We collect and use these categories of personal information for the business purposes described in our Privacy Policy’s How We Use Your Information section, in order to provide and manage our Service. We may disclose information in the following categories with our partners to assist with our operations and provide the Services: (a) Identifiers, (b) Customer Records, (c) Analytics, (d) Geolocation Data.
          </Trans>
        </Typography>
        <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>Right to Know</Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            If you are a California resident and use ChainSafe Storage as a private individual, you may have a right to receive a yearly accounting of:
          </Trans>
        </Typography>
        <ol className={clsx(classes.letteredList, classes.padSmall)}>
          <li>
            <Typography
              component="p"
              variant="body1"
              className={clsx(classes.text)}
            >
              <Trans>
                A description of the categories of personal information disclosed;
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography
              component="p"
              variant="body1"
              className={clsx(classes.text)}
            >
              <Trans>
                the right to know more about personal information that we have collected or disclosed, including
              </Trans>
            </Typography>
            <ol className={clsx(classes.romanList)}>
              <li>
                <Typography
                component="p"
                variant="body1"
                className={clsx(classes.text)}
                >
                  <Trans>
                    The categories of personal information that we have collected or shared about you in the preceding twelve (12) months,
                  </Trans>
                </Typography>
              </li>
              <li>
                <Typography
                component="p"
                variant="body1"
                className={clsx(classes.text)}
                >
                  <Trans>
                    The categories of sources from which we have collected that information in the preceding twelve (12) months,
                  </Trans>
                </Typography>
              </li>
              <li>
                <Typography
                component="p"
                variant="body1"
                className={clsx(classes.text)}
                >
                  <Trans>
                    The commercial or business reason(s) we have collected or shared that information,
                  </Trans>
                </Typography>
              </li>
              <li>
                <Typography
                component="p"
                variant="body1"
                className={clsx(classes.text)}
                >
                  <Trans>
                    The categories of third-parties with whom we have shared that information in the preceding twelve (12) months, and
                  </Trans>
                </Typography>
              </li>
              <li>
                <Typography
                component="p"
                variant="body1"
                className={clsx(classes.text)}
                >
                  <Trans>
                    Pursuant to a verifiable request, the specific pieces of information that we have collected about you.
                  </Trans>
                </Typography>
              </li>
            </ol>
          </li>
        </ol>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text)}
        >
          <Trans>
            <strong>ChainSafe does not sell information.</strong> Depending on the nature of your request, we may need additional information to verify your identity.
          </Trans>
        </Typography>
        <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>Right to Delete</Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            You also have the right to delete the personal information we have collected from you. However, this is not an absolute right, and ChainSafe may have legal grounds for keeping such data. To exercise any of these rights, please submit a request to <a href="mailto:support@chainsafe.io" className={classes.emailLink}>support@chainsafe.io</a>. In the request, please specify which right you are seeking to exercise and the scope of the request. We will confirm receipt of your request within fourteen (14) days. We may require specific information from you to help us verify your identity and process your request. If we are unable to verify your identity, we may deny your requests to know or delete it.
          </Trans>
        </Typography>
        <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>Shine the Light</Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            If you are a resident of California, you may request a list of the categories of third-parties to whom we disclose such information. To exercise a request, please contact us at <a href="mailto:support@chainsafe.io" className={classes.emailLink}>support@chainsafe.io</a>. Requests must include “California Shine the Light Request” in the subject line and include your name, street address, city, state, and ZIP code. We may require additional information from you to allow us to verify your identity. This may only be requested once during any calendar year.
          </Trans>
        </Typography>
        <Typography
          component="h2"
          variant="h3"
          className={clsx(classes.title, classes.padLarge)}
        >
          <Trans>Non-Discrimination</Trans>
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={clsx(classes.text, classes.padSmall)}
        >
          <Trans>
            If you choose to exercise any of the rights listed above, you have the right to non-discriminatory treatment by ChainSafe.
          </Trans>
        </Typography>
      </div>
    </div>
  )
}

export default PrivacyPolicy
