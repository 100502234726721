import React from "react"
import { createStyles, ITheme, makeStyles } from "@chainsafe/common-theme"
import { Grid, Typography } from "@chainsafe/common-components"
import { Trans } from "@lingui/macro"

const useStyles = makeStyles(
  ({ breakpoints, palette, zIndex, constants }: ITheme) => {
    return createStyles({
      container: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        background: "#0f0f0f",
        height: `${constants.headerHeight}px`,
        position: "fixed",
        zIndex: zIndex?.layer4,
        [breakpoints.up("sm")]:{
          padding: "0 1rem",
        },
        [breakpoints.down("sm")]: {
          padding: "0 .5rem",
        },
      },
      logo: {
        width: constants.generalUnit * 4,
        height: constants.generalUnit * 4,
        margin: constants.generalUnit,
      },
      navlink: {
        color: palette.common.white.main,
        textDecoration: "none",
        fontWeight: 600,
        marginRight: constants.generalUnit,
        "&:hover": {
          color: palette.additional["gray"][6],
          transition: "ease-in 0.2s",
        },
      },
      separator: {
        color: palette.common.black.main,
      },
    })
  },
)

const NavBar: React.FC = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
        <Grid item justifyContent="center" alignItems="flex-start">
          <Grid container alignItems="center">
            <img
              className={classes.logo}
              src="/ChainSafe_Logo.png"
              alt="ChainSafe Logo"
            />
            <Typography variant="h5">
              <a href="/" className={classes.navlink}>
                <Trans>ChainSafe Storage</Trans>
              </a>
            </Typography>
            </Grid>
          </Grid>
           <Grid item justifyContent="flex-end" alignItems="center" flexDirection="row">
            <Typography variant="h5">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.storage.chainsafe.io/"
                className={classes.navlink}
                >
                <Trans>Docs</Trans>
              </a>
            </Typography>
      </Grid>
    </div>
  )
}

export default NavBar
