import React from "react";
import {ITheme, makeStyles, createStyles} from "@chainsafe/common-theme";
import {Typography} from "@chainsafe/common-components";
import clsx from "clsx";
import {Trans} from "@lingui/macro";

const useStyles = makeStyles(
    ({constants, palette, breakpoints, typography}: ITheme) => {
    return createStyles({
        container: {
          overflowX: "hidden",
          background: "url('/test.png')",
          objectFit: "cover",
          objectPosition: "center",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          [breakpoints.down(767)]:{
            alignItems: "flex-start",
          },
        },
        gridContainer: {
          paddingTop: "80px",
          paddingLeft: "20px",
          paddingRight: "20px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "1300px",
        },
        bold: {
          fontWeight: 800,
        },
        reduceLeading: {
          letterSpacing: "-1px",
        },
        white: {
          color: palette.common.white.main,
        },
        hoverLink: {
          transition: "all ease-in-out 0.15s",
          "&:hover": {
            color: palette.additional["yellow"][7],
          }
        },
        hoverDarken: {
         transition: "all ease-in-out 0.2s",
          "&:hover": {
            filter: "brightness(80%)",
          }
        },
        yellow:{ 
          color: palette.additional["yellow"][5],
      },
      gray: {
          color: palette.additional["gray"][6],
      },
              singleColText: {
          [breakpoints.up('md')]:{
            textAlign: "center",
          },
          [breakpoints.down('md')]: {
            textAlign: "left"
          },
          maxWidth: 600,
        },
        featuredItem: {
          [breakpoints.down(1300)]: {
            width: "calc(80% - 50px))",
            marginLeft: "20px",
            marginBottom: "80px",
            marginRight: "20px",
          },  
          [breakpoints.down(1079)]: {
            width: "calc(80% - 40px))",
            marginLeft: "20px",
            marginRight: "20px",
            marginBottom: "50px",
          },
          [breakpoints.down(767)]:{
            marginBottom: "50px",
          },
          [breakpoints.down(599)]: {
            width: "calc(100% - 20px)",
            marginLeft: "10px",
            marginRight: "10px",
          },
          width: "calc(80% - 100px)",
          marginLeft: "20px",
          marginBottom: "80px",
          marginRight: "20px",
          display: "block",
          float: "left",
          "&:first-of-type": {
            [breakpoints.up(1079)]: {
              paddingRight: "3rem",
            }
          }
        },
        itemThumbnail: {
          width: "100%",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          "& > span": {
            [breakpoints.up(1480)]: {
              textAlign: "center",
            },
            [breakpoints.down(599)]: {
              textAlign: "center",
            },
          }
        },
      italic: {
        fontStyle: "italic",    
      }
    })
})

const Featured: React.FC = () => {
  const classes = useStyles();
  return (
    <section className={classes.container}>
      <div className={classes.gridContainer}>
          <article className={classes.featuredItem}>
            <Typography variant="h1" className={clsx(classes.yellow, classes.italic, classes.bold, classes.reduceLeading)}>
            <Trans>About</Trans>
            </Typography>
            <br/>
            <br/>
            <Typography variant="h3" className={clsx(classes.gray, classes.bold, classes.reduceLeading)}>
            <Trans>ChainSafe Storage is an underlying layer of <a href="https://files.chainsafe.io" className={clsx(classes.hoverLink, classes.yellow)}>Files</a>, our end-to-end encrypted file storage app.</Trans>
            </Typography>
            <br/>
            <br/>
            <Typography variant="h3" className={clsx(classes.white, classes.bold, classes.reduceLeading)}>
            <Trans>ChainSafe Storage exists to:</Trans>
            </Typography>
            <br/>
            <br/>
            <Typography variant="h3" className={clsx(classes.white, classes.bold, classes.reduceLeading)}>
            <Trans>1. Provide automatic, simple and effective access to IPFS and Filecoin.</Trans>
            </Typography>
            <br/>
            <br/>
            <Typography variant="h3" className={clsx(classes.white, classes.bold, classes.reduceLeading)}>
            <Trans>2. Create an easy migration path to decentralize any web2 storage layer that is S3 compatible.</Trans>
            </Typography>
            <br/>
            <br/>
            <Typography variant="h3" className={clsx(classes.white, classes.bold, classes.reduceLeading)}>
            <Trans>3. Provide a distributed base layer for creating new applications, both web2 and web3.</Trans>
            </Typography>
  
        </article>
      </div>

    </section>
  )
}

export default Featured;